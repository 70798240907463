@import "metis-mixins";

// Margins
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
.mt-auto { margin-top: auto; }
.mb-auto { margin-bottom: auto; }

// Positions
.is-absolute { position: absolute; }
.is-fixed { position: fixed; }

.is-right { right: 0; }
.is-left { left: 0; }
.is-bottom { bottom: 0; }
.is-top { top: 0; }

.is-centered-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) !important;
}

.is-transparent { background-color: transparent; }

.is-fullwidth { width: 100%; }
.is-fullheight { height: 100%; }
.is-fullsize {
  width: 100%;
  height: 100%;
}

.is-cover-256x256 {
  width: 256px;
  height: 256px;
  object-fit: cover;
}

.is-64x64 {
  width: 64px;
  height: 64px;
}

.is-radius { border-radius: 6px; }
.is-rounded { border-radius: 290486px; }

.is-object-cover { object-fit: cover; }

// Borders
.has-border-bottom {
  border-bottom: 1px solid rgba(218, 218, 218, 0.3);
}

.has-border-top {
  border-top: 1px solid rgba(218, 218, 218, 0.3);
}

.has-border-horizontal {
  border-top: 1px solid rgba(218, 218, 218, 0.3);
  border-bottom: 1px solid rgba(218, 218, 218, 0.3);
}

// Max width
.is-max-w-0 { max-width: 0rem; }
.is-max-w-none { max-width: none; }
.is-max-w-xs { max-width: 20rem; }
.is-max-w-sm { max-width: 24rem; }
.is-max-w-md { max-width: 28rem; }
.is-max-w-lg { max-width: 32rem; }
.is-max-w-xl { max-width: 36rem; }
.is-max-w-2xl { max-width: 42rem; }
.is-max-w-3xl { max-width: 48rem; }
.is-max-w-4xl { max-width: 56rem; }
.is-max-w-5xl { max-width: 64rem; }
.is-max-w-6xl { max-width: 72rem; }
.is-max-w-7xl { max-width: 80rem; }
.is-max-w-full { max-width: 100%; }

.is-background-cover { background-size: cover; }
.is-background-contain { background-size: contain; }
.is-background-no-repeat { background-repeat: no-repeat; }

$heights: (80);
$base: 0.25;

@each $height in $heights {
  .is-h-#{$height} {
    height: #{$height * $base}rem !important;
  }
}

@include make-rwd("is-w-half", ("desktop")) {
  width: 50%;
}

@include make-rwd("is-flex-dir-row", ("desktop")) {
  display: flex;
  flex-direction: row
}

@include make-rwd("is-flex-dir-row-reverse", ("desktop")) {
  display: flex;
  flex-direction: row-reverse;
}

@include make-rwd("is-flex-dir-col-reverse", ("touch")) {
  display: flex;
  flex-direction: column-reverse;
}
