$family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$family-primary: $family-sans-serif;
$family-secondary: $family-sans-serif;

$blue: #2563EB;
$green: #22C55E;
$orange: #F97316;
$yellow: #FBBF24;
$red: #EF4444;
$turquoise: #2DD4BF;
$cyan: #06B6D4;
$purple: #A855F7;

$white-ter: #F3F4F6;
$white-bis: #F9FAFB;

$grey-lighter: #F8FAFC;
$grey-light: #E2E8F0;
$grey: #94A3B8;
$grey-dark: #475569;
$grey-darker: #1E293B;

$black: #1C1917;
$black-bis: #171717;
$black-ter: #18181B;

$primary: $blue;
$info: #60A5FA;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-dark;
$text: $grey-dark;

$text-strong: $grey-darker;

$size-1: 3rem; // text-5xl
$size-2: 2.25rem; // text-4xl
$size-3: 1.875rem; // text-3xl
$size-4: 1.5rem; // text-2xl
$size-5: 1.25rem; // text-lg
$size-6: 1rem; // text-base
$size-7: 0.75rem; // text-xs

$title-weight: 700;
$title-size: $size-2;
$title-line-height: 1.5;

$subtitle-size: $size-6;
$subtitle-line-height: 2;
$subtitle-color: $grey;

